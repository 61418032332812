import React from 'react';
import PropTypes from 'prop-types';

import { Button, Flex, Heading2 } from '@ubisend/pulse-components';

const QuestionsFooter = ({ selectedUtterances, handleDelete, handleClear }) => {
  return (
    <Flex between>
      <Heading2>
        {`${selectedUtterances.length} question${
          selectedUtterances.length === 1 ? '' : 's'
        } selected.`}
      </Heading2>
      <Flex xSpace>
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          aria-label="button-delete-all"
          onClick={handleDelete}>
          Delete
        </Button>
        <Button icon="x" onClick={handleClear}>
          Clear
        </Button>
      </Flex>
    </Flex>
  );
};

QuestionsFooter.propTypes = {
  selectedUtterances: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired
};

export default QuestionsFooter;
