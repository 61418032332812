import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  StretchPanel as Panel,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  Pagination,
  Button,
  Checkbox,
  FixedFooter,
  EmptyStatePlaceholder,
  Flex,
  useNotification,
  useModal,
  TableActions,
  OrderableTableHeadCell,
  ConfidenceFormatter
} from '@ubisend/pulse-components';
import { useQuery, useQueryClient } from '@ubisend/pulse-hooks';
import { PermissionFilter } from '@ubisend/pulse-auth';

import TrainingWindow from './TrainingWindow';
import MessageFooter from './MessageFooter';
import MultiExplore from './MultiExplore';
import { getIntentLink, tableMessage } from '../utils/index';
import { changeMappedIntent, markAsCorrect, ignore } from '../api/index';

const getMessage = (id, messages) => {
  const [searchedMessage] = messages.filter(message => message.id === id);

  if (!searchedMessage) {
    return null;
  }

  return {
    ...searchedMessage,
    // It's possible for a message to not return any intent from the NLP
    // service, this will result in no trained intent.
    hasMappedIntent: Boolean(searchedMessage.trained_intent)
  };
};

const Messages = ({
  query: queryKey,
  multi = true,
  filters,
  pagination,
  order,
  showActions = true
}) => {
  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const [chosenMessageId, setChosenMessageId] = useState();
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [multiRemap, setMultiRemap] = useState();

  const queryClient = useQueryClient();
  const query = useQuery(
    [queryKey, { ...filters, ...pagination.params, ...order.params }],
    {
      refetchOnMount: 'always'
    }
  );

  const allItemsSelected = useMemo(() => {
    if (!query.isSuccess) {
      return false;
    }

    return query.data.data
      .map(({ id }) => id)
      .every(id => selectedMessages.includes(id));
  }, [query.data, query.isSuccess, selectedMessages]);

  const handleIntentChange = (messageId, newIntent) => {
    const { trained_intent, message, hasMappedIntent } = getMessage(
      messageId,
      query.data.data
    );

    showModal({
      header: 'Train a message',
      body: hasMappedIntent
        ? `Are you sure you want to change "${message.text}" from "${trained_intent.name}" to "${newIntent.name}"?`
        : `Are you sure you want to map this unmapped intent to "${newIntent.name}"?`,
      handleConfirm: async () => {
        // Hit the api, then get fresh intents.
        await changeMappedIntent([messageId], newIntent.identifier);

        // Close the slider.
        setChosenMessageId(null);

        await queryClient.invalidateQueries(queryKey);
        hideModal();
        showSuccess('Message updated successfully.');
      }
    });
  };

  const handleMultpleIntentChange = (messageIds, newIntent) => {
    showModal({
      header: 'Train a message',
      body: `Are you sure you want to update the selected messages?`,
      handleConfirm: async () => {
        await changeMappedIntent(messageIds, newIntent.identifier);

        setSelectedMessages([]);
        setMultiRemap(null);

        await queryClient.invalidateQueries(queryKey);
        hideModal();
        showSuccess('Messages updated successfully.');
      }
    });
  };

  const handleMarkAsCorrect = async messageIds => {
    await markAsCorrect(messageIds);
    await queryClient.invalidateQueries(queryKey);

    showSuccess(
      `Message${
        messageIds.length > 1 ? 's' : ''
      } successfully marked as correct.`
    );
  };

  const handleIgnore = async messageIds => {
    await ignore(messageIds);
    await queryClient.invalidateQueries(queryKey);

    showSuccess(
      `Message${messageIds.length > 1 ? 's' : ''} successfully ignored.`
    );
  };

  const handleMessageSelect = messageId => {
    const selected = selectedMessages.includes(messageId);

    // Remove message, or add message.
    // Need to spread a Set to remove any duplicates in case we
    // get a high volume of clicks.
    setSelectedMessages(ids => {
      return selected
        ? [...new Set(ids.filter(id => id !== messageId))]
        : [...new Set(ids.concat([messageId]))];
    });
  };

  const handleSelectingAllItems = () => {
    // If all items on the current paginated page are
    // selected, remove them.
    if (allItemsSelected) {
      return setSelectedMessages(selected => [
        ...new Set(
          // Filter selected ids to not include messages on
          // the current paginated page.
          selected.filter(id => {
            return !query.data.data.map(({ id }) => id).includes(id);
          })
        )
      ]);
    }

    // If not all items on the paginated page are selected,
    // select them all.
    setSelectedMessages(selected => [
      ...new Set(selected.concat(query.data.data.map(({ id }) => id)))
    ]);
  };

  const handleIgnoreClick = async id => {
    await handleIgnore([id]);
    setChosenMessageId();
    setSelectedMessages([]);
  };

  const handleCorrectClick = async id => {
    await handleMarkAsCorrect([id]);
    setChosenMessageId();
    setSelectedMessages([]);
  };

  const handleExplore = () => {
    setMultiRemap(
      query.data.data.filter(item => selectedMessages.includes(item.id))
    );
  };

  const generateHeader = () => {
    if (query === 'training/trained') {
      return 'Trained as';
    }

    return 'Understood As';
  };

  return (
    <React.Fragment>
      <Panel mt>
        {query.showNoResultsMessage && (
          <EmptyStatePlaceholder
            type="training"
            heading="All trained, good job!"
            text="When users start talking to your chatbot, improvement opportunities will appear here."
            helpLink="/docs/2151317744/2152366090"
            helpText="Learn more about FAQs and training."
          />
        )}
        {query.showTable && (
          <>
            <Table loading={query.isLoading} loadingColumns={6}>
              <TableHead>
                <TableRow>
                  <PermissionFilter can="edit training messages">
                    {multi && (
                      <TableHeadCell>
                        <Flex>
                          <Checkbox
                            checked={allItemsSelected}
                            onChange={handleSelectingAllItems}
                            aria-label="select all"
                          />
                        </Flex>
                      </TableHeadCell>
                    )}
                  </PermissionFilter>
                  <OrderableTableHeadCell
                    row={{ label: 'Message', sort: 'message' }}
                    {...order.props}
                  />
                  <OrderableTableHeadCell
                    row={{ label: generateHeader(), sort: 'trained_intent' }}
                    {...order.props}
                  />
                  <OrderableTableHeadCell
                    row={{ label: 'Confidence', sort: 'intent_confidence' }}
                    {...order.props}
                  />
                  <OrderableTableHeadCell
                    row={{ label: 'Sent on', sort: 'sent_at' }}
                    {...order.props}
                  />
                  <TableHeadCell />
                </TableRow>
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((message, key) => (
                    <TableRow key={key}>
                      <PermissionFilter can="edit training messages">
                        {multi && (
                          <TableCell>
                            <Checkbox
                              checked={selectedMessages.includes(message.id)}
                              onChange={() => {
                                handleMessageSelect(message.id);
                              }}
                              aria-label={`batch-select-${key + 1}`}
                            />
                          </TableCell>
                        )}
                      </PermissionFilter>
                      <TableCell style={{ overflowWrap: 'anywhere' }}>
                        {tableMessage(
                          message.postback
                            ? message.postback
                            : message.message.text
                        )}
                      </TableCell>
                      <TableCell>
                        {message.trained_intent ? (
                          <Link
                            target="_blank"
                            to={getIntentLink(message.trained_intent)}>
                            {message.trained_intent.name}
                          </Link>
                        ) : (
                          `Unmapped`
                        )}
                      </TableCell>
                      <TableCell>
                        {message.trained_intent && (
                          <ConfidenceFormatter>
                            {message.trained_intent.confidence}
                          </ConfidenceFormatter>
                        )}
                        {!message.trained_intent && <>N/A</>}
                      </TableCell>
                      <TableCell>
                        {dayjs(message.sent_at).format('D MMM YYYY HH:mm')}
                      </TableCell>
                      <TableActions>
                        <PermissionFilter can="edit training messages">
                          {showActions && (
                            <>
                              <Button
                                icon="check"
                                variant="secondary"
                                onClick={() => {
                                  handleCorrectClick(message.id);
                                }}>
                                Correct
                              </Button>
                              <Button
                                icon="archive"
                                variant="secondary"
                                onClick={() => handleIgnoreClick(message.id)}>
                                Ignore
                              </Button>
                            </>
                          )}
                        </PermissionFilter>
                        <Button
                          icon="eye"
                          variant="secondary"
                          onClick={() => {
                            setChosenMessageId(message.id);
                          }}>
                          Explore
                        </Button>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </>
        )}
      </Panel>
      <AnimatePresence>
        {chosenMessageId && getMessage(chosenMessageId, query.data.data) && (
          <TrainingWindow
            handleHide={() => setChosenMessageId(null)}
            message={getMessage(chosenMessageId, query.data.data)}
            languageId={filters.language_id}
            handleIntentChange={handleIntentChange}
            actions={
              <PermissionFilter can="edit training messages">
                <Flex xSpace>
                  <Button
                    variant="secondary"
                    onClick={() => handleCorrectClick(chosenMessageId)}>
                    Correct
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => handleIgnoreClick(chosenMessageId)}>
                    Ignore
                  </Button>
                </Flex>
              </PermissionFilter>
            }
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {multiRemap && (
          <MultiExplore
            handleHide={() => setMultiRemap(null)}
            multiRemap={multiRemap}
            handleMultpleIntentChange={handleMultpleIntentChange}
            languageId={filters.language_id}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {selectedMessages.length > 0 && (
          <FixedFooter>
            <MessageFooter
              selectedMessages={selectedMessages}
              handleExplore={handleExplore}
              handleMarkAsCorrect={async (...args) => {
                await handleMarkAsCorrect(...args);
                setChosenMessageId();
                setSelectedMessages([]);
              }}
              handleIgnore={async (...args) => {
                await handleIgnore(...args);
                setChosenMessageId();
                setSelectedMessages([]);
              }}
              handleClear={() => setSelectedMessages([])}
            />
          </FixedFooter>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};

Messages.propTypes = {
  query: PropTypes.string.isRequired,
  multi: PropTypes.bool,
  showActions: PropTypes.bool,
  filters: PropTypes.shape({
    language_id: PropTypes.numbers
  }).isRequired,
  pagination: PropTypes.shape({
    params: PropTypes.object.isRequired,
    props: PropTypes.object.isRequired
  }).isRequired,
  order: PropTypes.shape({
    params: PropTypes.object.isRequired,
    props: PropTypes.object.isRequired
  }).isRequired
};

export default Messages;
export { getMessage };
