import { useState, useCallback } from 'react';

/**
 * Calculates DOM element dimensions based on a callback ref.
 */
const useElementDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: null, height: null });

  // Store the DOM node in state on initial render
  const handleRef = useCallback(ref => {
    if (!ref || !ref.offsetWidth || !ref.offsetHeight) {
      return;
    }

    setDimensions({
      width: ref.offsetWidth,
      height: ref.offsetHeight,
      ref: ref
    });
  }, []);

  return { handleRef, dimensions };
};

export default useElementDimensions;
