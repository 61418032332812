import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LoadingContainer, useNotification } from '@ubisend/pulse-components';
import { useQuery, useLanguages, useMutation } from '@ubisend/pulse-hooks';

import { BotSettingsContext } from '../Contexts/index';

const settingTypes = {
  converse: 'converse/settings',
  'full-page': 'full-page/settings',
  embed: 'embed/settings'
};

const BotSettingsProvider = ({
  type,
  updateBotSettings,
  updateBotLogo,
  deploymentDetails,
  children
}) => {
  const { defaultLanguage } = useLanguages();
  const { showSuccess } = useNotification();

  const [language, setLanguage] = useState(defaultLanguage);
  const [currentSetting, setCurrentSetting] = useState();
  const [settings, setSettings] = useState();
  const [allSettings, setAllSettings] = useState();
  const [path, setPath] = useState('/window/body/messages');
  const [loadingTabs, setLoadingTabs] = useState(false);

  const { i18n } = useTranslation();

  const getDefaultSetting = (data = null) => {
    if (type !== 'converse') {
      // TODO: split this provider into three, one for each bot.
      return allSettings;
    }

    if (data) {
      return data.find(setting => setting.is_primary === true);
    }
    return allSettings.find(setting => setting.is_primary === true);
  };

  const handleAllSettings = data => {
    if (currentSetting) {
      setSettings(data.find(setting => setting.id === currentSetting));
    } else {
      setSettings(getDefaultSetting(data));
    }
  };

  const { isLoading, refetch } = useQuery(settingTypes[type], {
    onSuccess: ({ data }) => {
      if (type === 'embed' || type === 'full-page') {
        setSettings(data);
      }

      if (type === 'converse') {
        setAllSettings(data);
        setLoadingTabs(false);
        handleAllSettings(data);
      }
    }
  });

  const handleCurrentSettingChange = id => {
    setCurrentSetting(id);
    setSettings(allSettings.find(setting => setting.id === id));
  };

  const updateSettings = useMutation(updateBotSettings, {
    onSuccess: () => {
      refetch();
    }
  });

  const focusBanner = () => setPath('/window/banner');

  const focusBot = () => setPath('/window/body/messages');

  const focusLauncher = () => setPath('/');

  const focusSettings = () => setPath('/window/body/settings');

  const handleTextChange = (key, charLimit) => event => {
    const val = event.target.value;

    if (charLimit && val.length > charLimit) {
      return;
    }

    setSettings(settings => ({
      ...settings,
      [key]: val
    }));
  };

  const handleColourChange = key => chosenColour => {
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        [key]: chosenColour.hex
      }
    }));
  };

  const handleColourChangeNested = (key, subkey) => chosenColour => {
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        [key]: {
          ...settings.styles[key],
          [subkey]: chosenColour.hex
        }
      }
    }));
  };

  const mutation = useMutation(updateBotLogo, {
    onSuccess: ({ data }) => {
      setSettings(settings => ({
        ...settings,
        logo: data.data.logo
      }));
      showSuccess('Successfully updated your chatbot logo.');
    }
  });

  const handleNewImage = async file => {
    await mutation.mutate({ botSettingId: settings.id, ...file });
  };

  const handleSubmit = async (dashboardName = null) => {
    const { styles } = settings;
    const {
      background,
      gradient,
      recievedMessage,
      sentMessage,
      textColour,
      ctaBackgroundColour,
      launcherBackgroundColour,
      fontSrc,
      headerFont,
      bodyFont,
      size,
      showPoweredByUbisend,
      footerUrl,
      footerImageUrl
    } = styles;

    // Only pull out the settings that are changeable by the user.
    // Passing any more will cause API to 403.
    if (type === 'full-page') {
      await updateSettings.mutate({
        ...settings,
        styles: {
          background,
          gradient,
          recievedMessage,
          sentMessage,
          textColour,
          ctaBackgroundColour,
          launcherBackgroundColour,
          fontSrc,
          headerFont,
          bodyFont,
          size,
          footerUrl,
          footerImageUrl
        }
      });
    } else {
      await updateSettings.mutate({
        ...settings,
        dashboard_name:
          typeof dashboardName === 'string'
            ? dashboardName
            : settings.dashboard_name,
        styles: {
          background,
          gradient,
          recievedMessage,
          sentMessage,
          textColour,
          ctaBackgroundColour,
          launcherBackgroundColour,
          fontSrc,
          headerFont,
          bodyFont,
          size,
          showPoweredByUbisend,
          footerUrl,
          footerImageUrl
        }
      });
    }

    showSuccess('Successfully updated your chatbot settings.');
  };

  useEffect(() => {
    i18n.changeLanguage(language.country_code);
  }, [i18n, language]);

  if (isLoading || !settings) {
    return <LoadingContainer />;
  }

  const name = settings.names?.find(name => name.language_id === language.id);

  const bannerTitle = settings.banner_titles?.find(
    title => title.language_id === language.id
  );
  const bannerSubtitle = settings.banner_subtitles?.find(
    subtitle => subtitle.language_id === language.id
  );
  const buttons = settings.buttons?.filter(
    button => button.language_id === language.id
  );

  const isPrimary = settings.is_primary;

  const deploymentScript = () => {
    if (type === 'converse') {
      return `<script src="${
        deploymentDetails.src + `&s=${settings.id}`
      }" id="chat-widget-1925"></script>`;
    }
    return deploymentDetails.script;
  };

  const context = {
    allSettings,
    currentSetting: currentSetting ?? getDefaultSetting()?.id,
    handleCurrentSettingChange,
    setDefaultSetting: () => setCurrentSetting(getDefaultSetting()?.id),
    fetchBotSettings: refetch,
    // Tabs
    loadingTabs,
    setLoadingTabs,
    // Settings
    settings,
    setSettings,
    type,
    isPrimary,
    // Deploy Script
    deploymentScript,
    deploymentInfo: deploymentDetails.info,
    // Language
    language,
    setLanguage,
    // Translated params
    settingsId: settings.id,
    name: name ? name.content.name : null,
    bannerTitle: bannerTitle ? bannerTitle.content.banner_title : null,
    bannerSubtitle: bannerSubtitle
      ? bannerSubtitle.content.banner_subtitle
      : null,
    buttons,
    // Preview
    path,
    setPath,
    focusBanner,
    focusBot,
    focusLauncher,
    focusSettings,
    // Handlers
    handleTextChange,
    handleNewImage,
    handleColourChange,
    handleColourChangeNested,
    // Form
    handleSubmit,
    valid: true // TODO: verify whether we need this.
  };

  return (
    <BotSettingsContext.Provider value={context}>
      {children}
    </BotSettingsContext.Provider>
  );
};

BotSettingsProvider.propTypes = {
  type: PropTypes.string.isRequired,
  getBotSettings: PropTypes.func.isRequired,
  updateBotLogo: PropTypes.func.isRequired,
  updateBotSettings: PropTypes.func.isRequired,
  deploymentDetails: PropTypes.object.isRequired
};

export default BotSettingsProvider;
