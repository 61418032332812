import React from 'react';

import { Button, Flex } from '@ubisend/pulse-components';

import { useConditional } from '../hooks/index';
import AddConditionalButton from './AddConditionalButton';

const ConditionalActions = () => {
  const { valid } = useConditional();

  return (
    <Flex xSpace right>
      <AddConditionalButton />
      <Button disabled={!valid} type="submit" variant="primary">
        Save
      </Button>
    </Flex>
  );
};

export default ConditionalActions;
