import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Link, useHistory } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Pagination,
  Button,
  EmptyStatePlaceholder,
  TableActions,
  Flex,
  usePaginationReducer,
  useOrderableTableReducer,
  useFilterReducer,
  FilterMenu,
  Label,
  Select,
  DateTimePicker,
  Divider,
  TabButton,
  TextInput,
  Checkbox,
  TableHeadCell,
  OrderableTableHeadCell,
  FixedFooter,
  useModal,
  ActionMenu,
  useNotification
} from '@ubisend/pulse-components';
import { PermissionFilter, FeatureFilter, useAuth } from '@ubisend/pulse-auth';
import { AnimateSharedLayout } from '@ubisend/framer-motion';

import SubscribersRouter from './SubscribersRouter';
import SubscriberFooter from './SubscriberFooter';
import {
  massDelete,
  massExportData,
  massExportTranscript
} from '../../api/subscribers';
import {
  deleteSubscriber as deleteSingleSubscriber,
  exportSubscriber as exportSingleSubscriber,
  exportTranscript as exportSingleTranscript
} from './Pages/Subscriber/api';

const USER_TYPES = {
  REGULAR: {
    name: 'Regular',
    value: 'REGULAR',
    params: {
      type: 'regular'
    }
  },
  DEMO: {
    name: 'Demo',
    value: 'DEMO',
    params: {
      type: 'demo'
    }
  }
};

const DATE_TYPES = {
  LAST_MESSAGE: {
    name: 'Last message',
    value: 'last_active_at'
  },
  CONVERSATION_STARTED: {
    name: 'Conversation started',
    value: 'created_at'
  }
};

const AUTHORISATION_STATUSES = [
  { label: 'Any', value: null },
  { label: 'Authorised', value: true },
  { label: 'Not authorised', value: false }
];

const defaultFilters = {
  id: null,
  type: USER_TYPES.REGULAR.value,
  date_type: DATE_TYPES.LAST_MESSAGE.value,
  authorised: null,
  keyword: '',
  start: new Date(dayjs().subtract(1, 'weeks')),
  end: new Date(dayjs().add('1', 'hours'))
};

const getTime = date => dayjs(date).format('h:mm:ss A');

const Subscribers = () => {
  const [selectedSubscribers, setSelectedSubscribers] = useState([]);

  const pagination = usePaginationReducer({ id: 'subscribers/conversations' });
  const order = useOrderableTableReducer({ id: 'subscribers/conversations' });
  const filters = useFilterReducer({
    id: 'subscribers',
    initialFilters: defaultFilters,
    options: { pagination }
  });
  const { showModal, hideModal } = useModal();
  const { user: loggedInUser } = useAuth();
  const history = useHistory();
  const { showSuccess } = useNotification();
  const queryClient = useQueryClient();

  const query = useQuery(
    [
      'subscribers/conversations',
      {
        id: filters.form.id,
        start: filters.form.start,
        end: filters.form.end,
        authorised: filters.form.authorised,
        date_type: filters.form.date_type,
        keyword: filters.form.keyword,
        ...USER_TYPES[filters.form.type].params,
        ...pagination.params,
        ...order.params
      }
    ],
    { cacheTime: 0 }
  );

  const exportSubscribers = useMutation(massExportData, {
    onSuccess: () => {
      showSuccess(
        'You will receive an email shortly with details on accessing the subscribers data'
      );
    }
  });

  const exportSubscriber = useMutation(exportSingleSubscriber, {
    onSuccess: () => {
      showSuccess(
        'You will receive an email shortly with details on accessing the subscribers data'
      );
    }
  });

  const exportTranscripts = useMutation(massExportTranscript, {
    onSuccess: () => {
      showSuccess(
        `You will receive an email shortly with details on accessing the subscribers transcript${
          selectedSubscribers.length > 0 ? 's' : ''
        }`
      );
    }
  });

  const exportTranscript = useMutation(exportSingleTranscript, {
    onSuccess: () => {
      showSuccess(
        'You will receive an email shortly with details on accessing the subscribers transcript'
      );
    }
  });

  const deleteSubscribers = useMutation(massDelete, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('subscribers/conversations');

      history.push('/conversations');
      showSuccess(
        `Successfully deleted subscriber${
          selectedSubscribers.length > 0 ? 's' : ''
        }`
      );
    }
  });

  const deleteSubscriber = useMutation(deleteSingleSubscriber, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('subscribers/conversations');

      history.push('/conversations');
      showSuccess('Successfully deleted subscriber');
    }
  });

  const allItemsSelected = useMemo(() => {
    if (!query.isSuccess) {
      return false;
    }

    return query.data.data
      .map(({ id }) => id)
      .every(id => selectedSubscribers.includes(id));
  }, [query.data, query.isSuccess, selectedSubscribers]);

  const handleSubscriberSelect = subscriberId => {
    const selected = selectedSubscribers.includes(subscriberId);

    setSelectedSubscribers(ids => {
      return selected
        ? [...new Set(ids.filter(id => id !== subscriberId))]
        : [...new Set(ids.concat([subscriberId]))];
    });
  };

  const handleSelectingAllItems = () => {
    if (allItemsSelected) {
      return setSelectedSubscribers(selected => [
        ...new Set(
          selected.filter(id => {
            return !query.data.data.map(({ id }) => id).includes(id);
          })
        )
      ]);
    }

    setSelectedSubscribers(selected => [
      ...new Set(selected.concat(query.data.data.map(({ id }) => id)))
    ]);
  };

  const handleTypeChange = option => {
    filters.updateFilters({
      type: option.value
    });
  };

  const handleAuthorisationStatusChange = option => {
    filters.updateFilters({
      authorised: option.value
    });
  };

  const handleNewStart = ([newStart]) => {
    filters.updateFilters({
      start: newStart
    });
  };

  const handleNewEnd = ([newEnd]) => {
    filters.updateFilters({
      end: newEnd
    });
  };

  const handleDateTypeChange = type => {
    filters.updateFilters({
      date_type: type.value
    });
  };

  const handleSubscriberId = e => {
    const id = e.target.value;

    filters.updateFilters({
      id
    });
  };

  const handleKeywordChange = e => {
    const keyword = e.target.value;

    filters.updateFilters({
      keyword
    });
  };

  const handleExportData = subscriberIds => {
    const isMulti = Array.isArray(subscriberIds);
    showModal({
      header: `Export Subscriber${
        subscriberIds.length > 1 && isMulti ? 's' : ''
      } data`,
      body: `Are you sure you want to export this subscribers data? You will receive an email at "${loggedInUser.email}" with a link to download all data stored on them.`,
      handleConfirm: async () => {
        try {
          isMulti
            ? await exportSubscribers.mutateAsync(subscriberIds)
            : await exportSubscriber.mutateAsync(subscriberIds);
        } finally {
          setSelectedSubscribers([]);
          hideModal();
        }
      }
    });
  };

  const handleExportTranscript = subscriberIds => {
    const isMulti = Array.isArray(subscriberIds);
    const pluralize = subscriberIds.length > 1 && isMulti;
    showModal({
      header: `Export Subscriber${pluralize ? 's' : ''} transcripts${
        pluralize ? 's' : ''
      }`,
      body: `Are you sure you want to export this subscribers transcript? You will receive an email at "${loggedInUser.email}" with a link to download their conversation transcript.`,
      handleConfirm: async () => {
        try {
          isMulti
            ? await exportTranscripts.mutateAsync(subscriberIds)
            : await exportTranscript.mutateAsync(subscriberIds);
        } finally {
          setSelectedSubscribers([]);
          hideModal();
        }
      }
    });
  };

  const handleDelete = subscriberIds => {
    const isMulti = Array.isArray(subscriberIds);

    showModal({
      header: `Delete`,
      body: `Are you sure you would like to delete all selected subscribers? This will remove all data associated with them, including all of their messages.`,
      handleConfirm: async () => {
        try {
          isMulti
            ? await deleteSubscribers.mutateAsync(subscriberIds)
            : await deleteSubscriber.mutateAsync(subscriberIds);
        } finally {
          setSelectedSubscribers([]);
          hideModal();
        }
      }
    });
  };

  return (
    <>
      <SubscribersRouter />
      <PageWrapper
        header="Conversations"
        subheader="View all chat transcripts"
        actions={
          <FilterMenu
            position={FilterMenu.POSITIONS.LEFT}
            buttonProps={{ loading: query.isLoading }}
            isValid={
              filters.filters.keyword.length > 0
                ? filters.filters.keyword.length > 2
                : true
            }
            {...filters.props}>
            <Flex col style={{ width: '20rem' }}>
              <Flex col fat>
                <Label htmlFor="type">User type</Label>
                <Select
                  id="type"
                  value={{
                    label: USER_TYPES[filters.filters.type].name,
                    value: filters.filters.type
                  }}
                  options={Object.values(USER_TYPES).map(type => {
                    return { label: type.name, value: type.value };
                  })}
                  onChange={handleTypeChange}
                />
              </Flex>
              <FeatureFilter feature="authorisation">
                <Flex col fat mt>
                  <Label htmlFor="type">Authorisation status</Label>
                  <Select
                    id="type"
                    value={AUTHORISATION_STATUSES.find(
                      status => filters.filters.authorised === status.value
                    )}
                    options={AUTHORISATION_STATUSES}
                    onChange={handleAuthorisationStatusChange}
                  />
                </Flex>
              </FeatureFilter>
              <Divider fullWidth />
              <Flex mb col>
                <Label>Filter by</Label>
                <Flex>
                  <AnimateSharedLayout>
                    {Object.values(DATE_TYPES).map(type => (
                      <TabButton
                        key={type.value}
                        active={type.value === filters.filters.date_type}
                        onClick={() => handleDateTypeChange(type)}>
                        {type.name}
                      </TabButton>
                    ))}
                  </AnimateSharedLayout>
                </Flex>
              </Flex>
              <Flex col fat mb>
                <Label htmlFor="subscriber-id">Subscriber ID</Label>
                <TextInput
                  type="number"
                  placeholder="100"
                  value={filters.filters.id}
                  id="subscriber-id"
                  onChange={handleSubscriberId}
                />
              </Flex>
              <Flex col fat mb>
                <Label htmlFor="keyword">Keyword</Label>
                <TextInput
                  placeholder="Hello"
                  value={filters.filters.keyword}
                  id="keyword"
                  onChange={handleKeywordChange}
                />
              </Flex>
              <Flex col fat mb>
                <Label htmlFor="start">Start</Label>
                <DateTimePicker
                  id="start"
                  date={filters.filters.start}
                  onChange={handleNewStart}
                />
              </Flex>
              <Flex col fat>
                <Label htmlFor="end">End</Label>
                <DateTimePicker
                  id="end"
                  date={filters.filters.end}
                  onChange={handleNewEnd}
                />
              </Flex>
            </Flex>
          </FilterMenu>
        }>
        <Flex col ySpace>
          <Panel>
            {query.showNoResultsMessage && (
              <EmptyStatePlaceholder
                type="conversations"
                heading="Nothing to show yet"
                text="When users start engaging with your automation, conversation logs will appear here."
                helpLink="/docs/2151317744/2153545794"
                helpText="Learn more about conversations."
              />
            )}
            {query.showTable && (
              <>
                <Table loading={query.isLoading} loadingColumns={5}>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell>
                        <Flex>
                          <Checkbox
                            checked={allItemsSelected}
                            onChange={handleSelectingAllItems}
                            aria-label="select-all-conversations"
                          />
                        </Flex>
                      </TableHeadCell>
                      <OrderableTableHeadCell
                        row={{ label: 'Subscriber ID', sort: 'id' }}
                        {...order.props}
                      />
                      <OrderableTableHeadCell
                        row={{
                          label: 'Last message sent at',
                          sort: 'last_active_at'
                        }}
                        {...order.props}
                      />
                      <OrderableTableHeadCell
                        row={{
                          label: 'Conversation Started on',
                          sort: 'created_at'
                        }}
                        {...order.props}
                      />
                      <OrderableTableHeadCell
                        row={{
                          label: 'Channel',
                          sort: null
                        }}
                        {...order.props}
                      />
                      <OrderableTableHeadCell
                        row={{
                          label: 'Messages sent',
                          sort: 'messages_count'
                        }}
                        {...order.props}
                      />
                    </TableRow>
                  </TableHead>

                  {query.isSuccess && (
                    <TableBody>
                      {query.data.data.map((subscriber, key) => (
                        <TableRow key={key}>
                          <TableCell>
                            <Checkbox
                              checked={selectedSubscribers.includes(
                                subscriber.id
                              )}
                              onChange={() => {
                                handleSubscriberSelect(subscriber.id);
                              }}
                              aria-label={`batch-select-${key + 1}`}
                            />
                          </TableCell>
                          <TableCell>{subscriber.id}</TableCell>
                          <TableCell>
                            {subscriber.last_active_at
                              ? `${new Date(
                                  subscriber.last_active_at
                                ).toDateString()} (${getTime(
                                  subscriber.last_active_at
                                )})`
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            <Flex>
                              {`${new Date(
                                subscriber.created_at
                              ).toDateString()} (${getTime(
                                subscriber.created_at
                              )})`}
                            </Flex>
                          </TableCell>
                          <TableCell>
                            {subscriber.channel?.driver?.name || 'N/A'}
                          </TableCell>
                          <TableCell>{subscriber.message_count}</TableCell>
                          <TableActions>
                            <ActionMenu
                              position={ActionMenu.POSITIONS.LEFT}
                              buttonProps={{
                                'aria-label': `toggle-conversation-menu-${key}`,
                                onMouseDown: event => event.stopPropagation(),
                                onMouseUp: event => event.stopPropagation()
                              }}>
                              <PermissionFilter can="view messages">
                                <Button
                                  as={Link}
                                  variant="inline"
                                  to={`/conversations/${subscriber.id}`}
                                  icon="eye">
                                  View
                                </Button>
                                <Divider mtNone mbNone />
                                <Button
                                  variant="inline"
                                  icon="download"
                                  onClick={() => {
                                    handleExportData(subscriber.id);
                                  }}>
                                  Export Data
                                </Button>
                                <Divider mtNone mbNone />
                                <Button
                                  variant="inline"
                                  icon="download"
                                  onClick={() => {
                                    handleExportTranscript(subscriber.id);
                                  }}>
                                  Export Transcript
                                </Button>
                                <Divider mtNone mbNone />
                                <Button
                                  variant="inline"
                                  colour="danger"
                                  icon="trash"
                                  onClick={() => {
                                    handleDelete(subscriber.id);
                                  }}>
                                  Delete
                                </Button>
                              </PermissionFilter>
                            </ActionMenu>
                          </TableActions>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
                {query.showPagination && (
                  <Pagination
                    pagination={query.data.meta}
                    {...pagination.props}
                  />
                )}
              </>
            )}
          </Panel>
          {selectedSubscribers.length > 0 && (
            <FixedFooter>
              <SubscriberFooter
                selectedSubscribers={selectedSubscribers}
                handleMassDataExport={async (...args) => {
                  await handleExportData(...args);
                }}
                handleMassTranscriptExport={async (...args) => {
                  await handleExportTranscript(...args);
                }}
                handleMassDelete={async (...args) => {
                  await handleDelete(...args);
                }}
                handleClear={() => setSelectedSubscribers([])}
              />
            </FixedFooter>
          )}
        </Flex>
      </PageWrapper>
    </>
  );
};

export default Subscribers;
export { getTime };
