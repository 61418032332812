import React from 'react';

import { Button, Flex } from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks/index';

const SaveSettings = () => {
  const { valid, handleSubmit, settings } = useBotSettings();

  // Only pull out the settings that are changeable by the user.
  // Passing any more will cause API to 403.
  const { styles } = settings;
  const {
    background,
    gradient,
    recievedMessage,
    sentMessage,
    textColour,
    ctaBackgroundColour,
    launcherBackgroundColour,
    fontSrc,
    headerFont,
    bodyFont,
    size,
    showPoweredByUbisend,
    footerUrl,
    footerImageUrl
  } = styles;

  return (
    <Flex right>
      <Button
        variant="primary"
        disabled={!valid}
        onClick={() =>
          handleSubmit({
            ...settings,
            styles: {
              background,
              gradient,
              recievedMessage,
              sentMessage,
              textColour,
              ctaBackgroundColour,
              launcherBackgroundColour,
              fontSrc,
              headerFont,
              bodyFont,
              size,
              showPoweredByUbisend,
              footerUrl,
              footerImageUrl
            }
          })
        }>
        Save
      </Button>
    </Flex>
  );
};

export default SaveSettings;
