import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  TableActions,
  Button,
  Checkbox,
  FixedFooter,
  Pagination,
  EmptyStatePlaceholder,
  useModal,
  useNotification,
  Flex,
  Tooltip,
  usePaginationReducer,
  OrderableTableHeadCell,
  useOrderableTableReducer,
  FilterMenu,
  useFilterReducer,
  Label
} from '@ubisend/pulse-components';
import {
  LocationsConsumer,
  useLanguages,
  useQuery,
  useQueryClient,
  useMutation
} from '@ubisend/pulse-hooks';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { GroupSelect } from '@ubisend/pulse-groups';

import {
  ExportFaqs,
  EditFaq,
  FaqSelect,
  LocationSelect,
  CreateFaqs
} from '../Components/index';
import { deleteFaq, deleteFaqs } from '../api/index';
import FaqsFooter from './FaqsFooter';

const defaultFilters = {
  groupIds: [],
  locations: [],
  faq_id: null
};

const Faqs = () => {
  const pagination = usePaginationReducer({ id: 'faqs' });
  const order = useOrderableTableReducer({ id: 'faqs' });
  const filters = useFilterReducer({
    id: 'faqs',
    initialFilters: defaultFilters,
    options: { pagination }
  });

  const [showEdit, setShowEdit] = useState(false);
  const [selectedFaqs, setSelectedFaqs] = useState([]);
  const [create, setCreate] = useState(false);

  const { showModal, hideModal } = useModal();
  const { showNotification } = useNotification();
  const { defaultLanguage } = useLanguages();

  const queryClient = useQueryClient();

  const params = {
    ...(!filters.form.faq_id && { ...pagination.params, ...order.params }),
    ...filters.form
  };

  const query = useQuery(['faqs', params]);
  const mutation = useMutation(deleteFaq, {
    onSuccess: () => {
      showNotification({ message: `FAQ was successfully deleted.` });
      queryClient.invalidateQueries(['faqs', params]);
    }
  });

  const mutationDeleteMutliple = useMutation(deleteFaqs, {
    onSuccess: () => {
      queryClient.invalidateQueries(['faqs', params]);
    }
  });

  const allItemsSelected = useMemo(() => {
    if (!query.isSuccess) {
      return false;
    }

    return query.data.data
      .map(({ id }) => id)
      .every(id => selectedFaqs.includes(id));
  }, [query.data, query.isSuccess, selectedFaqs]);

  const handleSelectingAllItems = () => {
    // If all items on the current paginated page are
    // selected, remove them.
    if (allItemsSelected) {
      return setSelectedFaqs(selected => [
        ...new Set(
          // Filter selected ids to not include faqs on
          // the current paginated page.
          selected.filter(id => {
            return !query.data.data.map(({ id }) => id).includes(id);
          })
        )
      ]);
    }

    // If not all items on the paginated page are selected,
    // select them all.
    setSelectedFaqs(selected => [
      ...new Set(selected.concat(query.data.data.map(({ id }) => id)))
    ]);
  };

  const handleDeleteAll = () => {
    showModal({
      header: `Delete FAQ${selectedFaqs.length > 1 ? 's' : ''}`,
      body: `Are you sure you want to delete ${
        selectedFaqs.length > 1 ? 'these FAQs' : 'this FAQ'
      }?`,
      handleConfirm: async () => {
        await mutationDeleteMutliple.mutateAsync({ faqIds: selectedFaqs });
        hideModal();
        showNotification({
          message: `FAQ${
            selectedFaqs.length > 1 ? 's' : ''
          } was successfully deleted.`
        });
        setSelectedFaqs([]);
        await queryClient.invalidateQueries('faqs');
      }
    });
  };

  const handleFaqDelete = ({ id, name }) => {
    showModal({
      header: 'Delete FAQ',
      body: `Are you sure you want to delete "${name}"?`,
      handleConfirm: () => {
        mutation.mutate(id);
        hideModal();
      }
    });
  };

  const handleGroupSelect = items => {
    filters.updateFilters({
      groupIds: items ? items.map(group => group.value) : []
    });
  };

  const handleFaqSelect = item => {
    filters.updateFilters({
      faq_id: item ? item.value : null
    });
  };

  const handleFaqsSelect = faqId => {
    const selected = selectedFaqs.includes(faqId);

    // Remove faq, or add faq.
    // Need to spread a Set to remove any duplicates in case we
    // get a high volume of clicks.
    setSelectedFaqs(ids => {
      return selected
        ? [...new Set(ids.filter(id => id !== faqId))]
        : [...new Set(ids.concat([faqId]))];
    });
  };

  const handleLocationSelect = items => {
    filters.updateFilters({
      locations: items ? items.map(item => item.value) : []
    });
  };

  const handleCreateFaqs = () => {
    setCreate(true);
  };

  return (
    <PageWrapper
      header="FAQs"
      subheader="View and manage FAQs"
      actions={
        <Flex xSpace>
          <FilterMenu
            position={FilterMenu.POSITIONS.LEFT}
            buttonProps={{
              loading: query.isLoading
            }}
            {...filters.props}>
            <Flex col style={{ width: '20rem' }}>
              <Flex fat col mb>
                <Label id="categories">FAQ</Label>
                <FaqSelect
                  value={filters.filters.faq_id}
                  onChange={handleFaqSelect}
                  selectedGroups={filters.filters.groupIds}
                  selectedLocations={filters.filters.locations}
                />
              </Flex>
              <LocationsConsumer>
                <Flex fat col mb>
                  <Label id="locations">Locations</Label>
                  <LocationSelect
                    value={filters.filters.locations}
                    onChange={handleLocationSelect}
                    isMulti
                  />
                </Flex>
              </LocationsConsumer>
              <Flex fat col>
                <Label htmlFor="tags">Tags</Label>
                <GroupSelect
                  id="tags"
                  value={filters.filters.groupIds}
                  onChange={handleGroupSelect}
                  for="faqs"
                  sortAlphabetically
                />
              </Flex>
            </Flex>
          </FilterMenu>
          {query.showTable && query.isSuccess && query.data.data.length > 0 && (
            <ExportFaqs />
          )}
          <PermissionFilter can="import faqs">
            <Button icon="arrowDownOnSquare" onClick={handleCreateFaqs}>
              Import FAQS
            </Button>
          </PermissionFilter>
          <PermissionFilter can="edit faq settings">
            <Button
              as={Link}
              variant="secondary"
              icon="cog"
              to={'/faqs/follow-up'}>
              Follow up
            </Button>
          </PermissionFilter>
          {query.isSuccess && !query.showNoResultsMessage && (
            <PermissionFilter can="create faqs">
              <Button variant="primary" as={Link} to={`/faqs/create`}>
                + New FAQ
              </Button>
            </PermissionFilter>
          )}
        </Flex>
      }>
      {create && <CreateFaqs handleClose={() => setCreate(false)} />}
      {showEdit && (
        <EditFaq handleCancel={() => setShowEdit(false)} faq={showEdit} />
      )}
      <Panel mt>
        {query.showNoResultsMessage && (
          <EmptyStatePlaceholder
            type="faqs"
            heading="Create your first FAQ"
            text="Answer user questions quickly and efficiently with FAQs."
            actions={
              <PermissionFilter can="edit faq settings">
                <Button variant="primary" as={Link} to={`/faqs/create`}>
                  + New FAQ
                </Button>
              </PermissionFilter>
            }
            helpLink="/docs/2151317744/2152300579"
            helpText="Learn more about FAQs."
          />
        )}
        {query.showTable && (
          <Table loading={query.isLoading} loadingColumns={5}>
            <TableHead>
              <TableRow>
                <PermissionFilter can="edit faqs">
                  <TableHeadCell>
                    <Flex>
                      <Checkbox
                        checked={allItemsSelected}
                        onChange={handleSelectingAllItems}
                        aria-label="select all"
                      />
                    </Flex>
                  </TableHeadCell>
                </PermissionFilter>
                <OrderableTableHeadCell
                  row={{ label: 'Name', sort: 'name' }}
                  {...order.props}
                />
                <TableHeadCell>Example Question</TableHeadCell>
                <LocationsConsumer>
                  <TableHeadCell>Location</TableHeadCell>
                </LocationsConsumer>
                <TableHeadCell>Tags</TableHeadCell>
                <OrderableTableHeadCell
                  row={{ label: 'Number of answers', sort: 'response_count' }}
                  {...order.props}
                />
                <TableHeadCell />
              </TableRow>
            </TableHead>
            {query.isSuccess && (
              <TableBody>
                {query.data.data.map((faq, key) => (
                  <TableRow key={key}>
                    <PermissionFilter can="edit faqs">
                      <TableCell>
                        <Checkbox
                          checked={selectedFaqs.includes(faq.id)}
                          onChange={() => {
                            handleFaqsSelect(faq.id);
                          }}
                          aria-label={`batch-select-${key + 1}`}
                        />
                      </TableCell>
                    </PermissionFilter>
                    <TableCell>
                      <Flex center>
                        <span>{faq.name}</span>
                      </Flex>
                    </TableCell>
                    <TableCell>
                      {
                        faq.intents.find(intent => {
                          return intent.language.id === defaultLanguage.id;
                        }).utterances[0].utterance
                      }
                    </TableCell>
                    <LocationsConsumer>
                      <TableCell>
                        {faq.location ? faq.location.name : 'No Location'}
                      </TableCell>
                    </LocationsConsumer>
                    <TableCell>
                      {faq.groups.length > 0
                        ? faq.groups.map(({ name }) => name).join(', ')
                        : 'No Tags'}
                    </TableCell>
                    <TableCell>
                      <Flex center>
                        {faq.response_count}
                        {faq.response_count === 0 && (
                          <TableCell>
                            <Tooltip
                              tooltip={
                                <Tooltip.Content>
                                  This FAQ has no answers. It must have at least
                                  one answer to be used within the bot.
                                </Tooltip.Content>
                              }>
                              <Tooltip.Icon>!</Tooltip.Icon>
                            </Tooltip>
                          </TableCell>
                        )}
                      </Flex>
                    </TableCell>
                    <TableActions>
                      <PermissionFilter can="edit faqs">
                        <Button
                          variant="secondary"
                          onClick={() => setShowEdit(faq)}
                          icon="pencilAlt">
                          Edit
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="edit faqs">
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/faqs/${faq.id}/questions`}>
                          Questions
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="edit faqs">
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/faqs/${faq.id}/responses`}>
                          Answers
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="delete faqs">
                        <Button
                          variant="secondary"
                          colour="danger"
                          icon="trash"
                          onClick={() => handleFaqDelete(faq)}>
                          Delete
                        </Button>
                      </PermissionFilter>
                    </TableActions>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        )}
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </Panel>
      <AnimatePresence>
        {selectedFaqs.length > 0 && (
          <FixedFooter>
            <FaqsFooter
              selectedFaqs={selectedFaqs}
              handleDelete={handleDeleteAll}
              handleClear={() => setSelectedFaqs([])}
            />
          </FixedFooter>
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default Faqs;
export { defaultFilters };
