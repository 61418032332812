import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { Markdown } from '@ubisend/pulse-markdown';
import { Button } from '@ubisend/pulse-components';

const Container = styled.div`
  ${tw`absolute flex items-center justify-center w-screen h-screen`}
  z-index: 1000;
  margin-top: -100%;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  margin-left: calc(384px - 100vw);
`;

const ContainerContent = styled.div`
  ${tw`flex flex-col bg-white rounded shadow border border-solid  overflow-y-auto overflow-x-auto`}
  max-height: 40rem;
  max-width: 60rem;
  width: 60rem;
`;

const ContentHeading = styled.div`
  ${tw`flex justify-between items-center rounded-tr rounded-tl text-xl font-bold px-6 py-4 font-poppins  border-0 border-b border-solid`};
`;

const ContentBody = styled.div`
  ${tw`px-6 py-4 font-light leading-normal`}
`;

const CloseModalButton = styled(Button)`
  border: none;
`;

CloseModalButton.defaultProps = {
  icon: 'x',
  colour: 'black'
};

const ContentModal = ({ content, onClose }) => (
  <Container>
    <ContainerContent>
      <ContentHeading>
        {content.value.title}
        <CloseModalButton onClick={onClose} />
      </ContentHeading>

      <ContentBody>
        <Markdown>{content.value.body}</Markdown>
      </ContentBody>
    </ContainerContent>
  </Container>
);

ContentModal.propTypes = {
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ContentModal;
