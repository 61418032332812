import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  DateTimePicker,
  useModal,
  useNotification,
  Label,
  Flex,
  LeftHalfLayout,
  RightHalfLayout,
  Panel,
  Checkbox,
  Button
} from '@ubisend/pulse-components';
import {
  ComposerSettings,
  Composer,
  ComposerSubmitButton,
  ComposerWidget,
  ComposerProvider,
  ComposerLanguageSelect
} from '@ubisend/pulse-composer';
import { AppComposerWidget } from '@ubisend/pulse-bot';

import { createBroadcast } from '../api/index';

const Broadcast = () => {
  const [sendNow, setSendNow] = useState(true);
  const [scheduleAt, setScheduleAt] = useState(dayjs().add(1, 'days').toDate());

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const queryClient = useQueryClient();
  const mutation = useMutation(createBroadcast, {
    onSuccess: () => {
      showSuccess('Successfully created broadcast');
      queryClient.invalidateQueries('broadcasts');
    }
  });

  const handleScheduleAtChange = ([date]) => {
    if (dayjs(date).isAfter(dayjs())) {
      setScheduleAt(date);
    }
  };

  const handleSendNowChange = () => setSendNow(sendNow => !sendNow);

  const handleMessageBroadcast = async responses => {
    if (sendNow) {
      return showModal({
        header: 'Broadcast your message',
        body:
          'Are you sure you want to brodcast this message? All subscribers currently using the chatbot will see it.',
        handleConfirm: async () => {
          try {
            // Trigger a broadcast over the API.
            await mutation.mutateAsync({ send_now: true, ...responses });
          } finally {
            hideModal();
          }
        }
      });
    }

    await mutation.mutateAsync({
      scheduled_at: dayjs(scheduleAt).format('YYYY-MM-DD HH:mm:ss'),
      send_now: false,
      ...responses
    });
  };

  return (
    <PageWrapper
      header="Broadcast Messages"
      subheader="Send a message to every subscriber"
      actions={
        <Button as={Link} to="/broadcast/scheduled">
          Your scheduled broadcasts
        </Button>
      }>
      <ComposerProvider>
        <Flex>
          <LeftHalfLayout>
            <Panel
              divider
              header="Create a broadcast message"
              actions={<ComposerLanguageSelect />}>
              <Composer />
              <Flex col mb ySpace>
                <Checkbox
                  checked={sendNow}
                  onChange={handleSendNowChange}
                  label="Send now"
                />
                {!sendNow && (
                  <Flex mb col>
                    <Label>Deliver Message At</Label>
                    <DateTimePicker
                      date={scheduleAt}
                      onChange={handleScheduleAtChange}
                    />
                  </Flex>
                )}
              </Flex>
              <Flex xSpace>
                <ComposerSettings />
              </Flex>
              <Flex right>
                <ComposerSubmitButton onClick={handleMessageBroadcast}>
                  {sendNow ? 'Broadcast Message' : 'Schedule Message'}
                </ComposerSubmitButton>
              </Flex>
            </Panel>
          </LeftHalfLayout>
          <RightHalfLayout>
            <Flex fat middle>
              <ComposerWidget as={AppComposerWidget} />
            </Flex>
          </RightHalfLayout>
        </Flex>
      </ComposerProvider>
    </PageWrapper>
  );
};

export default Broadcast;
