import tw, { styled } from 'twin.macro';

import { pseudoStyles } from '@ubisend/pulse-components';

const ComposerButton = styled.button`
  ${tw`flex items-center bg-transparent border-0 cursor-pointer h-10 rounded p-2`};
  ${props => props.disabled && tw`cursor-not-allowed`}

  svg {
    ${tw`w-5 h-5 fill-current`};
    opacity: 0.75;
    .primary {
      opacity: 0.75;
      fill: ${props => props.theme.bot.gradient.to};
    }
    .secondary {
      fill: ${props => props.theme.bot.gradient.from};
    }
    .white {
      fill: #ffffff;
    }
  }
  ${pseudoStyles}
`;

export default ComposerButton;
