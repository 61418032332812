import React from 'react';
import tw from 'twin.macro';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Markdown } from '@ubisend/pulse-markdown';
import { Flex } from '@ubisend/pulse-components';

import {
  Header,
  CloseWidgetButton,
  BackArrowButton,
  Name
} from '../Header/index';
import { useContent, useBot } from '../../../../hooks/index';
import IframeModalButton from '../../Components/IframeModalButton';

const Content = () => {
  const { title, content } = useContent();
  const { showClose } = useBot();

  const { t } = useTranslation(['bots', 'converse']);

  const history = useHistory();

  const closeWidget = () => {
    history.push('/');
  };

  const handleBack = () => history.goBack();

  return (
    <>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <BackArrowButton
          aria-label={t('back_arrow_button_label', { ns: 'bots' })}
          onClick={handleBack}
        />
        <Name size={tw`text-xl`}>{title}</Name>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {showClose && (
            <CloseWidgetButton
              aria-label={t('close_widget_button_label', { ns: 'converse' })}
              onClick={closeWidget}
            />
          )}
        </div>
      </Header>
      <div
        style={{
          flexGrow: '1',
          padding: '1rem',
          overflowY: 'auto',
          position: 'relative'
        }}>
        <Flex right>
          <IframeModalButton
            data-testid="content_full_screen"
            content={{
              title: title,
              body: content
            }}
          />
        </Flex>
        <Markdown>{content}</Markdown>
      </div>
    </>
  );
};

export default Content;
