import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { Link, useLocation, Redirect } from 'react-router-dom';
import chroma from 'chroma-js';

import { useQuery, useQuerySearch } from '@ubisend/pulse-hooks';
import {
  Flex,
  EmptyStatePlaceholder,
  Grid,
  Pagination,
  Placeholder,
  TextInput,
  TabButton,
  InnerPanel,
  StretchPanel,
  Button,
  FilterMenu,
  Select,
  Label,
  usePaginationReducer,
  useFilterReducer,
  Checkbox,
  FixedFooter,
  Heading2,
  useNotification,
  useModal
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';
import { AnimateSharedLayout, AnimatePresence } from '@ubisend/framer-motion';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';

import {
  FilePreview,
  CreateFile,
  SourceSelect,
  SourceSelectMenuList
} from '../../Components/index';
import types from '../../Components/Types/index';
import { useFileSource } from '../../hooks/index';
import { FileRouter } from './Routers/index';
import { FileSourceContext } from '../../Contexts/index';
import { formatFileSource } from '../FileSources/utils/index';
import { deleteFiles as deleteFilesApi } from '../../api/index';

const FileContainer = styled(StretchPanel)`
  ${tw`w-full`}

  aspect-ratio: 1 / 1;

  ${props => {
    if (props.isActive) {
      return `
      border: 1px solid ${chroma(props.theme.primary).alpha(0.5)};
      box-shadow: 0 0 0 0.2rem ${chroma(props.theme.primary).alpha(0.25)};
      `;
    }
  }}

  & a {
    color: inherit;
    text-decoration: inherit;
  }

  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
`;

const uploadedBy = [
  {
    value: 'user',
    label: 'Platform user'
  },
  {
    value: 'subscriber',
    label: 'Chatbot user'
  },
  {
    value: 'anyone',
    label: 'Anyone'
  }
];

const FileSourceProvider = ({ children, sourceId }) => {
  const query = useQuery(`files/sources/${sourceId}`);

  return (
    <FileSourceContext.Provider
      value={query.isSuccess ? formatFileSource(query.data.data) : null}>
      {children}
    </FileSourceContext.Provider>
  );
};

FileSourceProvider.propTypes = {
  sourceId: PropTypes.number.isRequired
};

const File = ({ children, file }) => {
  const location = useLocation();

  return (
    <FileContainer isActive={location.pathname === `/files/${file.id}`}>
      {children}
    </FileContainer>
  );
};

File.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

const FilesPage = ({ match, location, history }) => {
  const source = useFileSource();
  const subscriberId = useQuerySearch(location, 'subscriber_id');

  const [type, setType] = useState(types.image.type);
  const [sourceId, setSourceId] = useState(parseInt(match.params.sourceId));
  const [selected, setSelected] = useState([]);

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const pagination = usePaginationReducer({
    options: { perPageOptions: null }
  });
  const filters = useFilterReducer({
    initialFilters: {
      search: '',
      group_ids: [],
      uploaded_by: 'user'
    },
    options: { pagination }
  });

  const queryClient = useQueryClient();
  const query = useQuery([
    'files',
    {
      ...filters.form,
      ...pagination.params,
      type,
      subscriber_id: subscriberId ? subscriberId : null,
      source_id: sourceId
    }
  ]);
  const deleteFiles = useMutation(deleteFilesApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('files');
      history.push(`/files/${match.params.sourceId}/view`);
      await queryClient.invalidateQueries(`files/${match.params.fileId}`);
      showSuccess('Successfully deleted files');
    }
  });

  const handleSourceChange = source => {
    setSourceId(source.value);
  };

  const handleTypeChange = type => {
    setType(type);
  };

  const handleSearchChange = event => {
    const search = event.target.value;

    filters.updateFilters({ search });
  };

  const handleGroupIdsChange = options => {
    filters.updateFilters({
      group_ids: options ? options.map(option => option.value) : []
    });
  };

  const handleUploadedByChange = ({ value }) => {
    filters.updateFilters({ uploaded_by: value });
  };

  const buildFileLink = fileId => {
    const baseLink = `/files/${sourceId}/view/${fileId}`;

    if (subscriberId) {
      return baseLink + `?subscriber_id=${subscriberId}`;
    }

    return baseLink;
  };

  const isLoading = query.isLoading || query.isIdle || !source;

  const handleCheckboxClick = file => {
    const isSelected = selected.find(id => id === file.id);

    if (isSelected) {
      setSelected(ids => ids.filter(id => id !== file.id));
    } else {
      setSelected(ids => ids.concat(file.id));
    }
  };

  const handleDeleteAll = () => {
    showModal({
      header: 'Delete files',
      body: `Are you sure you want to delete these files?`,
      handleConfirm: async () => {
        await deleteFiles.mutateAsync({
          ids: selected,
          source_id: sourceId
        });
        setSelected([]);
        hideModal();
      }
    });
  };

  const handleClearAll = () => {
    setSelected([]);
  };

  if (parseInt(match.params.sourceId) !== sourceId) {
    return <Redirect to={`/files/${sourceId}/view`} />;
  }

  return (
    <>
      <Flex col fat>
        <Flex fat background="white" pad borderBottom xSpace>
          <Flex fat xSpace center>
            <FilterMenu
              position={FilterMenu.POSITIONS.RIGHT}
              buttonProps={{ loading: isLoading }}
              {...filters.props}>
              <Flex col fat style={{ width: '20rem' }}>
                <Flex col fat mb>
                  <Label htmlFor="search">Name or description</Label>
                  <TextInput
                    id="search"
                    placeholder="Search"
                    disabled={source ? !source.driver.features.search : true}
                    value={filters.filters.search}
                    onChange={handleSearchChange}
                  />
                </Flex>
                <Flex col fat>
                  <Label htmlFor="tags">Tags</Label>
                  <GroupSelect
                    id="tags"
                    aria-label="Tags"
                    value={filters.filters.group_ids}
                    onChange={handleGroupIdsChange}
                    isDisabled={source ? !source.driver.features.groups : true}
                    for="files"
                  />
                </Flex>
                {!subscriberId && (
                  <Flex col fat mt>
                    <Label htmlFor="uploaded_by">Uploaded by</Label>
                    <Select
                      id="uploaded_by"
                      placeholder="Uploaded by"
                      options={uploadedBy}
                      value={uploadedBy.filter(
                        ({ value }) => filters.filters.uploaded_by === value
                      )}
                      onChange={handleUploadedByChange}
                    />
                  </Flex>
                )}
              </Flex>
            </FilterMenu>
            <Flex fat middle>
              <AnimateSharedLayout>
                {Object.values(types).map((mappedType, key) => (
                  <TabButton
                    style={{ whiteSpace: 'nowrap' }}
                    key={key}
                    active={mappedType.type === type}
                    onClick={() => handleTypeChange(mappedType.type)}>
                    {mappedType.name}
                  </TabButton>
                ))}
              </AnimateSharedLayout>
            </Flex>
          </Flex>
          <Flex right xSpace>
            {!subscriberId && (
              <>
                <Flex style={{ width: '13rem' }}>
                  <SourceSelect
                    components={{ MenuList: SourceSelectMenuList }}
                    aria-label="Storage"
                    queryKey={'files/sources'}
                    value={sourceId}
                    onChange={handleSourceChange}
                  />
                </Flex>
                <PermissionFilter can="create files">
                  <Flex right>
                    <CreateFile
                      type={type}
                      sourceId={sourceId}
                      queryKey={'files'}
                      disabled={!source}
                    />
                  </Flex>
                </PermissionFilter>
              </>
            )}
            {subscriberId && (
              <>
                <PermissionFilter can="view messages" fallback="N/A">
                  <Flex start>
                    <Button
                      as={Link}
                      variant="secondary"
                      to={`/conversations/${subscriberId}`}
                      icon="user">
                      Subscriber {subscriberId}
                    </Button>
                  </Flex>
                </PermissionFilter>
                <Flex start>
                  <Button
                    colour="danger"
                    as={Link}
                    variant="secondary"
                    to={`/files/${sourceId}/view`}
                    icon="x">
                    Clear subscriber
                  </Button>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
        <Flex fat>
          <Flex fat yScroll style={{ height: 'calc(100vh - 4.5rem)' }}>
            {isLoading && (
              <Flex col pl pr pt fat>
                <Grid columns={4}>
                  {[0, 1, 2, 3, 4, 5].map(i => (
                    <InnerPanel key={i}>
                      <Flex fat>
                        <Placeholder items={1} />
                      </Flex>
                    </InnerPanel>
                  ))}
                </Grid>
              </Flex>
            )}
            {!isLoading && query.isSuccess && query.data.meta.total === 0 && (
              <Flex middle center fat>
                <EmptyStatePlaceholder
                  customHeight="250px"
                  type="files"
                  heading="No files found"
                />
              </Flex>
            )}
            {!isLoading && query.isSuccess && query.data.meta.total > 0 && (
              <Flex col pl pr pt fat>
                <Grid columns={4}>
                  {query.data.data.map((file, key) => (
                    <Flex relative key={key}>
                      <Flex
                        absolute
                        pinTop
                        pinLeft
                        padSm
                        background="white"
                        borderColor
                        style={{
                          width: '2.2rem',
                          zIndex: 1,
                          borderWidth: '0px',
                          borderLeftWidth: '1px',
                          borderTopWidth: '1px',
                          borderStyle: 'solid'
                        }}>
                        <Checkbox
                          aria-label={`Select file ${file.id}`}
                          checked={Boolean(selected.find(id => id === file.id))}
                          onChange={() => handleCheckboxClick(file)}
                        />
                      </Flex>
                      <File file={file}>
                        <Link to={buildFileLink(file.id)}>
                          <FilePreview
                            type={type}
                            sourceId={sourceId}
                            file={file}
                          />
                        </Link>
                      </File>
                    </Flex>
                  ))}
                </Grid>
                {query.showPagination && (
                  <Pagination
                    pagination={query.data.meta}
                    {...pagination.props}
                  />
                )}
              </Flex>
            )}
          </Flex>
          <Flex
            borderLeft
            style={{ width: '360px', height: 'calc(100vh - 4.5rem)' }}
            background="white"
            noShrink
            yScroll>
            <FileRouter sourceId={sourceId} />
          </Flex>
        </Flex>
      </Flex>
      <AnimatePresence>
        {selected.length > 0 && (
          <FixedFooter
            style={{
              width: 'calc(100vw - 12rem - 360px)',
              right: 'unset'
            }}>
            <Flex between>
              <Heading2>
                {`${selected.length} file${
                  selected.length === 1 ? '' : 's'
                } selected.`}
              </Heading2>
              <Flex xSpace>
                <Button
                  icon="trash"
                  colour="danger"
                  onClick={handleDeleteAll}
                  loading={deleteFiles.isLoading}>
                  Delete all
                </Button>
                <Button icon="x" onClick={handleClearAll}>
                  Clear
                </Button>
              </Flex>
            </Flex>
          </FixedFooter>
        )}
      </AnimatePresence>
    </>
  );
};

const Files = props => {
  return (
    <FileSourceProvider sourceId={parseInt(props.match.params.sourceId)}>
      <FilesPage {...props} />
    </FileSourceProvider>
  );
};

export default Files;
