import { styled } from 'twin.macro';

import Panel from './Panel';
import { spacing, flex } from '../styles';

const Card = styled(Panel)`
  ${spacing}
  ${flex}
`;

export default Card;
