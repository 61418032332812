import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  StretchPanel as Panel,
  useNotification,
  Flex,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableActions,
  TableCell,
  Button,
  Checkbox,
  FixedFooter,
  Pagination,
  useModal,
  PageWrapper,
  usePaginationReducer,
  useOrderableTableReducer,
  OrderableTableHeadCell
} from '@ubisend/pulse-components';
import {
  useLanguages,
  useQueryClient,
  useQuery,
  useMutation
} from '@ubisend/pulse-hooks';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  EditUtterance,
  CreateUtterance,
  LanguageSelect
} from '../Components/index';
import {
  createUtterance as createUtteranceApi,
  updateUtterance as updateUtteranceApi,
  deleteUtterance as deleteUtteranceApi,
  deleteUtterances as deleteUtterancesApi
} from '../api/index';
import QuestionsFooter from './QuestionsFooter';

const Questions = ({
  match: {
    params: { faqId }
  }
}) => {
  const pagination = usePaginationReducer({ id: 'faq-questions' });
  const order = useOrderableTableReducer({ id: 'faq-questions' });

  const { defaultLanguage } = useLanguages();
  const { showSuccess } = useNotification();
  const queryClient = useQueryClient();
  const { showModal, hideModal } = useModal();

  const [editUtterance, setEditUtterance] = useState(false);
  const [creatingUtterance, setCreatingUtterance] = useState(false);
  const [language, setLanguage] = useState(defaultLanguage.id);

  const utteranceQuery = useQuery([
    `faqs/${faqId}/utterances`,
    { ...pagination.params, ...order.params, language_id: language }
  ]);
  const faqQuery = useQuery([`faqs/${faqId}`, { language_id: language }]);

  const [selectedUtterances, setSelectedUtterances] = useState([]);

  const allItemsSelected = useMemo(() => {
    if (!utteranceQuery.isSuccess) {
      return false;
    }

    return utteranceQuery.data.data
      .map(({ id }) => id)
      .every(id => selectedUtterances.includes(id));
  }, [utteranceQuery.data, utteranceQuery.isSuccess, selectedUtterances]);

  const handleSelectingAll = () => {
    // If all items on the current paginated page are
    // selected, remove them.
    if (allItemsSelected) {
      return setSelectedUtterances(selected => [
        ...new Set(
          // Filter selected ids to not include faqs on
          // the current paginated page.
          selected.filter(id => {
            return !utteranceQuery.data.data.map(({ id }) => id).includes(id);
          })
        )
      ]);
    }

    // If not all items on the paginated page are selected,
    // select them all.
    setSelectedUtterances(selected => [
      ...new Set(selected.concat(utteranceQuery.data.data.map(({ id }) => id)))
    ]);
  };

  const { mutate: createUtterance } = useMutation(createUtteranceApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(`faqs/${faqId}/utterances`);
      showSuccess(`Successfully created question`);
      setCreatingUtterance(false);
    }
  });

  const { mutate: updateUtterance } = useMutation(updateUtteranceApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(`faqs/${faqId}/utterances`);
      showSuccess(`Successfully updated question`);
      setEditUtterance(false);
    }
  });

  const { mutate: deleteUtterance } = useMutation(deleteUtteranceApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(`faqs/${faqId}/utterances`);
      showSuccess(`Successfully deleted question`);
    }
  });

  const { mutate: deleteUtterances } = useMutation(deleteUtterancesApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(`faqs/${faqId}/utterances`);
      showSuccess(
        `Successfully deleted question${
          selectedUtterances.length > 1 ? 's' : ''
        }.`
      );
      setSelectedUtterances([]);
    }
  });

  const handleCreateUtterance = (event, text) => {
    event.preventDefault();
    createUtterance({
      faqId,
      utterance: {
        utterance: text,
        language_id: language
      }
    });
  };

  const handleUpdateUtterance = ({ id, text }) => {
    updateUtterance({
      faqId,
      utteranceId: id,
      utterance: {
        utterance: text,
        language_id: language
      }
    });
  };

  const handleDeleteUtterance = id => {
    showModal({
      header: 'Remove Question',
      body: `Are you sure you want to remove this question?`,
      handleConfirm: () => {
        deleteUtterance({
          faqId,
          utteranceId: id
        });
        hideModal();
      }
    });
  };

  const handleDeleteAll = () => {
    showModal({
      header: `Remove Question${selectedUtterances.length > 1 ? 's' : ''}`,
      body: `Are you sure you want to remove ${
        selectedUtterances.length > 1 ? 'these questions' : 'this question'
      }?`,
      handleConfirm: () => {
        deleteUtterances({
          faqId,
          utteranceIds: { utteranceIds: selectedUtterances }
        });
        hideModal();
      }
    });
  };

  const handleSelect = faqId => {
    const selected = selectedUtterances.includes(faqId);

    // Remove faq, or add faq.
    // Need to spread a Set to remove any duplicates in case we
    // get a high volume of clicks.
    setSelectedUtterances(ids => {
      return selected
        ? [...new Set(ids.filter(id => id !== faqId))]
        : [...new Set(ids.concat([faqId]))];
    });
  };

  const handleLanguageChange = option => {
    pagination.resetPage();
    setSelectedUtterances([]);
    setLanguage(option.value);
  };

  const generateHeader = () => {
    if (faqQuery.isLoading) {
      return 'Questions';
    }

    if (!faqQuery.data.data.location) {
      return `Questions for ${faqQuery.data.data.name}`;
    }

    return `Questions for ${faqQuery.data.data.name} (${faqQuery.data.data.location.name})`;
  };

  return (
    <>
      {editUtterance && (
        <EditUtterance
          utterance={editUtterance}
          handleCancel={() => setEditUtterance(false)}
          handleSubmit={handleUpdateUtterance}
        />
      )}
      {creatingUtterance && (
        <CreateUtterance
          handleCancel={() => setCreatingUtterance(false)}
          handleSubmit={handleCreateUtterance}
        />
      )}
      <PageWrapper
        header={generateHeader()}
        subheader="Manage how an FAQ can be phrased"
        actions={
          <Flex xSpace>
            <PermissionFilter can="create faqs">
              <Button
                as={Link}
                variant="secondary"
                to={`/faqs/${faqId}/duplicate`}
                icon="clipboardCopy">
                Duplicate
              </Button>
            </PermissionFilter>
            <Button
              as={Link}
              variant="secondary"
              to={`/faqs/${faqId}/responses`}>
              Answers
            </Button>
            <Button
              variant="primary"
              icon="plus"
              onClick={() => setCreatingUtterance(true)}>
              New Question
            </Button>
          </Flex>
        }>
        <Panel mt>
          <Table loading={utteranceQuery.isLoading} loadingColumns={2}>
            <TableHead>
              <TableRow>
                <PermissionFilter can="edit faqs">
                  <TableHeadCell style={{ width: '0.5rem' }}>
                    <Flex>
                      <Checkbox
                        checked={allItemsSelected}
                        onChange={handleSelectingAll}
                        aria-label="select all"
                      />
                    </Flex>
                  </TableHeadCell>
                </PermissionFilter>
                <OrderableTableHeadCell
                  row={{ label: 'Question', sort: 'utterance' }}
                  {...order.props}
                />
                <TableHeadCell style={{ width: '10rem', fontWeight: '500' }}>
                  <LanguageSelect
                    value={language}
                    onChange={handleLanguageChange}
                  />
                </TableHeadCell>
              </TableRow>
            </TableHead>
            {utteranceQuery.isSuccess && (
              <TableBody>
                {utteranceQuery.data.data.map(({ id, utterance }, key) => (
                  <TableRow key={key}>
                    <PermissionFilter can="edit faqs">
                      <TableCell>
                        <Checkbox
                          checked={selectedUtterances.includes(id)}
                          onChange={() => {
                            handleSelect(id);
                          }}
                          aria-label={`batch-select-${key + 1}`}
                        />
                      </TableCell>
                    </PermissionFilter>
                    <TableCell>{utterance}</TableCell>
                    <TableActions>
                      <Button
                        variant="secondary"
                        onClick={() =>
                          setEditUtterance({ id, text: utterance })
                        }
                        icon="pencilAlt">
                        Edit
                      </Button>
                      <Button
                        variant="secondary"
                        colour="danger"
                        icon="trash"
                        onClick={() => handleDeleteUtterance(id)}
                        disabled={utteranceQuery.data.data.length === 1}>
                        Delete
                      </Button>
                    </TableActions>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {utteranceQuery.showPagination && (
            <Pagination
              pagination={utteranceQuery.data.meta}
              {...pagination.props}
            />
          )}
        </Panel>
        <AnimatePresence>
          {selectedUtterances.length > 0 && (
            <FixedFooter>
              <QuestionsFooter
                selectedUtterances={selectedUtterances}
                handleDelete={handleDeleteAll}
                handleClear={() => setSelectedUtterances([])}
              />
            </FixedFooter>
          )}
        </AnimatePresence>
      </PageWrapper>
    </>
  );
};

export default Questions;
