import React from 'react';
import { useQuery } from 'react-query';
import chroma from 'chroma-js';

import { PulseContext } from '@ubisend/pulse-hooks';
import { ThemeProvider } from '@ubisend/pulse-components';

import { VERSION } from '../config/constants';

const PulseProvider = ({ children }) => {
  const { data } = useQuery('pulse', {
    keepPreviousData: true
  });

  if (!data) {
    return null;
  }

  const { theme, ...owner } = data.owner;

  const pulse = {
    versions: {
      ui: VERSION,
      api: data.version
    },
    owner,
    platform: data.platform
  };

  return (
    <ThemeProvider
      theme={{
        ...theme,
        bot: {
          showPoweredByUbisend: true,
          // Colours
          textColour: theme.white,
          background: theme.white,
          gradient: { from: theme.secondary, to: theme.secondary },
          // Messages
          sentMessage: { text: theme.white, background: theme.secondary },
          recievedMessage: {
            text: theme.black,
            background: chroma(theme.grey).alpha(0.2)
          },
          // Call-to-action.
          ctaBackgroundColour: theme.primary,
          // Launcher
          launcherBackgroundColour: theme.primary
        }
      }}>
      <PulseContext.Provider value={pulse}>{children}</PulseContext.Provider>
    </ThemeProvider>
  );
};

export default PulseProvider;
