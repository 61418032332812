import React from 'react';
import { Link } from 'react-router-dom';

import { useQueryClient, useQuery, useMutation } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Pagination,
  EmptyStatePlaceholder,
  StretchPanel as Panel,
  TableActions,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
  useNotification,
  usePaginationReducer,
  Button,
  OrderableTableRow,
  useOrderableTableReducer
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { deleteEmail } from '../api/index';

const Emails = () => {
  const { showSuccess } = useNotification();

  const pagination = usePaginationReducer({ id: 'emails' });
  const order = useOrderableTableReducer({ id: 'emails' });

  const queryClient = useQueryClient();
  const query = useQuery(['emails', { ...pagination.params, ...order.params }]);
  const mutation = useMutation(deleteEmail, {
    onSuccess: () => {
      showSuccess('Successfully deleted email');
      queryClient.invalidateQueries('emails');
    }
  });

  const handleEmailDelete = id => () => {
    mutation.mutate(id);
  };

  return (
    <PageWrapper
      header="Emails"
      subheader="Manage emails"
      actions={
        query.isSuccess &&
        !query.showNoResultsMessage && (
          <PermissionFilter can="create emails">
            <Button
              as={Link}
              to="/emails/create"
              variant="primary"
              aria-label="Create email">
              + New Email
            </Button>
          </PermissionFilter>
        )
      }>
      <Panel>
        {query.showNoResultsMessage && (
          <EmptyStatePlaceholder
            type="emails"
            heading="Create your first email"
            text="Send automated email notifications as part of your workflows. "
            actions={
              <PermissionFilter can="create emails">
                <Button
                  variant="primary"
                  as={Link}
                  to="/emails/create"
                  aria-label="Create email">
                  + New Email
                </Button>
              </PermissionFilter>
            }
            helpLink="/docs/2151317744/2153054484"
            helpText="Learn more about emails."
          />
        )}
        {query.showTable && (
          <>
            <Table loading={query.isLoading} loadingColumns={6}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Subject', sort: 'subject' },
                    { label: 'From', sort: null },
                    { label: 'To', sort: 'to' },
                    { label: 'Cc', sort: 'cc' },
                    { label: 'Bcc', sort: 'bcc' },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((email, key) => (
                    <TableRow key={key}>
                      <TableCell>{email.subject}</TableCell>
                      <TableCell>
                        {email.from_name} ({email.from_address})
                      </TableCell>
                      <TableCell>{email.to}</TableCell>
                      <TableCell>{email.cc || 'N/A'}</TableCell>
                      <TableCell>{email.bcc || 'N/A'}</TableCell>
                      <TableActions>
                        <PermissionFilter can="edit emails">
                          <Button
                            variant="secondary"
                            as={Link}
                            to={`/emails/${email.id}`}
                            icon="pencilAlt">
                            Edit
                          </Button>
                        </PermissionFilter>
                        <PermissionFilter can="delete emails">
                          <Button
                            variant="secondary"
                            colour="danger"
                            onClick={handleEmailDelete(email.id)}
                            icon="trash">
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </>
        )}
      </Panel>
    </PageWrapper>
  );
};

export default Emails;
